import React, {useState, useEffect, useRef, useCallback} from "react";
import {Collapse, Form, message} from "antd";
import {DollarOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {ApiContactType, LabourCostUnit, NewRecordState, ResourceUnitType} from "../../../../constants/constants";
import {loadContacts} from "../../../../services/contacts";

import {continueEdit} from "../../../../redux/reducers/detailsPanel";
import {isRequestCanceled, errorFromHttpResponse} from "../../../../helpers/error";
import {useSelector, useDispatch} from "react-redux";
import InformationBlock from "./InformationBlock";
import {createPosition, loadPositions, updatePosition} from "../../../../services/positions";
import CostDetailsBlock from "./CostDetailsBlock";

const InformationHeader = (<>
    <InfoCircleOutlined/> Information
</>);

const CostDetailsHeader = (<>
    <DollarOutlined/> Cost Details
</>);

const prepareFormData = (position) => {
    const formData = {...position};

    return formData;
};

const PositionDetailsPanel = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const firstInputRef = useRef();
    const nextInputRef = useRef();
    const [editItemState, setEditItemState] = useState(NewRecordState.Empty);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const isOptionalFieldDisabled = editItemState !== NewRecordState.ReadyForEdit;
    const allOrganizations = useSelector((state) => state.contacts.contacts).filter((c) => c.Type === ApiContactType.Organization);
    const rulesets = useSelector((state) => state.labour.rulesets || []);
    const workWeekSettings = useSelector((state) => state.workWeekSettings.settings);

    useEffect(() => {
        if (!activePortfolio) return;

        if (allOrganizations.length === 0) {
            loadContacts(activePortfolio.Id);
        }
    }, [activePortfolio]);

    useEffect(() => {
        if (!itemToEdit) return;

        setEditItemState(NewRecordState.ReadyForEdit);

        const formData = prepareFormData(itemToEdit);
        form.setFieldsValue(formData);
    }, [itemToEdit]);

    const prepareAndCreate = useCallback((finishedForm) => {
        const payload = {
            Name: finishedForm.Name, AccountId: activePortfolio.Id, RulesetId: finishedForm.Ruleset?.Id,
        };

        createPosition(payload)
            .catch((err) => {
                const errorText = errorFromHttpResponse(err);
                message.error(errorText);
                throw new Error(errorText);
            })
            .then((response) => {
                const item = {Id: response.Id, Name: payload.Name};
                dispatch(continueEdit({item}));
                setEditItemState(NewRecordState.ReadyForEdit);
                loadPositions(payload.AccountId);
                message.success(`${item.Name} was created`);
            })
            .catch(console.error);

        setEditItemState(NewRecordState.RequiredFieldsSubmitted);
    }, [itemToEdit?.Id, dispatch]);

    const prepareAndUpdate = useCallback((finishedForm) => {
        const updatePayload = {
            Id: itemToEdit.Id,
            Name: finishedForm.Name,
            Description: finishedForm.Description,
            DepartmentTagId: finishedForm.DepartmentTagId,

            SourceId: finishedForm.Source?.Id,
            RulesetId: finishedForm.Ruleset?.Id,

            Cost: finishedForm.Cost,
            Unit: finishedForm.Unit,
        };

        updatePosition(updatePayload)
            .then((response) => {
                dispatch(continueEdit({item: response}));
                loadPositions(activePortfolio.Id);
            })
            .catch((err) => {
                if (!isRequestCanceled(err)) {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    console.error(err);
                }
            });
    }, [itemToEdit?.Id, dispatch]);

    const onFinish = useCallback((finishedForm) => {
        if (editItemState === NewRecordState.Empty) {
            prepareAndCreate(finishedForm);
        }
        if (editItemState === NewRecordState.ReadyForEdit) {
            prepareAndUpdate(finishedForm);
        }
    }, [editItemState, prepareAndCreate, prepareAndUpdate]);

    const save = useCallback(async () => {
        try {
            const values = isOptionalFieldDisabled ? await form.getFieldsValue() : await form.validateFields();

            if (!isOptionalFieldDisabled || values.Name) {
                setEditItemState(NewRecordState.ReadyForEdit);
                onFinish(values);
            }
        } catch (err) {
            console.error(err);
        }
    }, [form, onFinish]);

    return (<div className="side-panel with-collapse">
        <Collapse defaultActiveKey={["Information"]}>
            <Collapse.Panel key="Information" header={InformationHeader}>
                <Form
                    initialValues={{Ruleset: {Id: rulesets[0]?.Id}}}
                    form={form}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    layout="horizontal"
                    size="middle">
                    <InformationBlock
                        rulesets={rulesets}
                        form={form}
                        save={save}
                        firstInputRef={firstInputRef}
                        nextInputRef={nextInputRef}
                        itemToEdit={itemToEdit}
                        isOptionalFieldDisabled={isOptionalFieldDisabled}
                    />
                </Form>
            </Collapse.Panel>
        </Collapse>
        {!isOptionalFieldDisabled
            && itemToEdit
            && itemToEdit.Unit !== ResourceUnitType.Flat
            && itemToEdit.Ruleset && (
                <Collapse defaultActiveKey={["Cost Details"]}>
                    <Collapse.Panel key="Cost Details" header={CostDetailsHeader}>
                        <Form
                            labelCol={{span: 8}}
                            wrapperCol={{span: 16}}
                            layout="horizontal"
                            size="middle">
                            <CostDetailsBlock
                                rulesets={rulesets}
                                workWeekSettings={workWeekSettings}
                                itemToEdit={itemToEdit}
                            />
                        </Form>
                    </Collapse.Panel>
                </Collapse>)}
    </div>);
};

export default PositionDetailsPanel;
