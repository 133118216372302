import React, {useState} from "react";
import {message, Tag, Badge} from "antd";
import {getProject} from "../../services/projects";
import FilterBar from "./FilterBar/ProjectsFilterBar";
import {ApiProjectType, SidePanelItemType} from "../../constants";
import {startLoading, endLoading} from "../../redux/reducers/detailsPanel";
import {useDispatch, useSelector} from "react-redux";
import GroupProjectsTable from "./GroupProjectsTable";
import "./ProjectsTable.scss";
import {dateRange} from "../../helpers/time";
import {formatContactName} from "../../helpers/text";
import {formatAmount} from "../../helpers/money";
import GoTo from "../Common/Projects/GoTo";
import TableWithGroups from "../Common/Tables/TableWithGroups";
import EditAction from "../Common/Tables/Actions/EditAction";
import useDateTimeFormat from "../../hooks/useDateTimeFormat";
import {ProjectsTableFooter, GroupProjectsTableFooter} from "./ProjectsTableFooter";
import {setSortOrderState} from '../../redux/reducers/projects';

const statusBadges = {
    [ApiProjectType.Inactive]: "Draft",
};

const goToColumn = {
    title: "", dataIndex: "", width: "90px", render: (i, record) => <GoTo project={record}></GoTo>,
};

const ProjectsTable = ({items = [], isActiveProjectsTab = false, loading}) => {
    const dispatch = useDispatch();
    const sortOrderState = useSelector((state) => state.projects.sortOrderState);
    const dateTimeFormats = useDateTimeFormat();
    const [currentGroupBy, setCurrentGroupBy] = useState();

    const columns = [
        {
            title: "Project",
            dataIndex: "Name",
            key: "Id",
            sorter: !currentGroupBy ? (a, b) => {
                const nameA = a.Name || '';
                const nameB = b.Name || '';

                return nameA.localeCompare(nameB);
            } : undefined,
            defaultSortOrder: sortOrderState && sortOrderState.field === 'Name' ? sortOrderState.order : null,
            render: (name, record) => (<>
                <Tag className="project_tag" color={record.Color}>
                    {name}
                </Tag>{" "}
                <Badge className="project-status-badge" count={statusBadges[record.State]}></Badge>
            </>),
        }, {
            title: "Date",
            dataIndex: "ProjectDate",
            width: "15%",
            sorter: !currentGroupBy ? (a, b) => {
                const dateA = a.ProjectDate ? new Date(a.ProjectDate) : new Date(0);
                const dateB = b.ProjectDate ? new Date(b.ProjectDate) : new Date(0);
                return dateA - dateB;
            } : undefined,
            defaultSortOrder: sortOrderState && sortOrderState.field === 'ProjectDate' ? sortOrderState.order : null,
            render: (projectDate, record) => (<div>
                {dateRange(record.ProjectDate, record.ProjectDate === record.ProjectEndDate ? null : record.ProjectEndDate, dateTimeFormats)}
            </div>),
        }, {
            title: "Categories",
            dataIndex: "CategoryTags",
            width: "10%",
            render: (items = []) => items.map((x) => x.Name).join(", "),
        }, {
            title: "Project Managers",
            dataIndex: "ProjectAccountMembers",
            render: (members = {AssistantMembers: []}) => members?.AssistantMembers.map((m) => formatContactName(m.CreatedFromContact, false)).join(", "),
            width: "15%",
        }, {
            title: "Item Cost", dataIndex: "ItemCost", width: "130px", render: (r) => formatAmount(r || 0),
        }, {
            title: "Labour Cost", dataIndex: "LabourCost", width: "130px", render: (r) => formatAmount(r || 0),
        }, {
            title: "Total Cost",
            dataIndex: "LabourCost",
            width: "130px",
            render: (c, record) => formatAmount(record.ItemCost + record.LabourCost),
        },];

    function loadItemToEdit(project) {
        dispatch(startLoading({item: project, type: SidePanelItemType.Project}));
        getProject(project.Id)
            .then((project) => dispatch(endLoading({item: project, type: SidePanelItemType.Project})))
            .catch(() => message.error("Server error"));
    }

    const currentColumns = [...columns];
    if (isActiveProjectsTab) {
        currentColumns.push(goToColumn);
    }

    const EditProject = (props) => <EditAction onClick={loadItemToEdit} {...props} />;

    const sortOrder = sortOrderState ? [{field: sortOrderState.field, order: sortOrderState.order}] : [];

    return (
        <TableWithGroups
            loading={loading}
            filterBarProps={{isActiveProjectsTab}}
            items={items}
            columns={currentColumns}
            FilterBar={FilterBar}
            GroupTable={GroupProjectsTable}
            loadItemToEdit={loadItemToEdit}
            footer={ProjectsTableFooter}
            groupFooter={GroupProjectsTableFooter}
            actions={[EditProject]}
            onChange={(pagination, filters, sorter) => {
                const newSortOrder = {field: sorter.field, order: sorter.order};
                if (!sortOrderState || sortOrderState.field !== sorter.field || sortOrderState.order !== sorter.order) {
                    dispatch(setSortOrderState(newSortOrder));
                }
            }}
            onGroupByChange={setCurrentGroupBy}
            sortOrder={sortOrder}
        />
    );
};

export default ProjectsTable;
