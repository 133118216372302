import {createSlice} from "@reduxjs/toolkit";

const workWeekSlice = createSlice({
    name: "workWeek",
    initialState: {
        settings: {
            StartDayOfWeek: 0,
            StandardHoursThreshold: null,
            OvertimeRate: null,
            AccountId: 0
        }
    },
    reducers: {
        setWorkWeekSettings(state, action) {
            state.settings = action.payload;
        }
    },
});

export const {setWorkWeekSettings} = workWeekSlice.actions;

export default workWeekSlice.reducer;