import {Form, Row, Col} from "antd";
import React from "react";
import {InputNumberWithFormItem, TextAreaWithFormItem} from "../../../Common/Input";
import {formatDurationFromHours} from "../../../../helpers/time";
import {formatAmount} from "../../../../helpers/money";
import "./FinancialsBlock.scss";

const FinancialsBlock = ({itemToEdit, save, isOptionalFieldDisabled, workWeekSettings}) => {
    const {
        TotalHours,
        RegularTotalCost,
        RegularHours,
        Overtime1Cost,
        Overtime2Cost,
        Overtime1Hours,
        Overtime2Hours,
        TotalCost,
        WeeklyRegularOvertimeHours,
        WeeklyRegularOvertimeCost,
    } = itemToEdit;

    return (<div className="financials-block">
        <InputNumberWithFormItem disabled={isOptionalFieldDisabled} name="MealBreak" label="+/- Hours"
                                 onChanged={save}/>
        <TextAreaWithFormItem
            label="Details"
            disabled={isOptionalFieldDisabled}
            name="Notes"
            onChanged={save}
            autoSize={{minRows: 2}}
            placeholder="Enter details"
        />
        <Row gutter={16}>
            <Col span={12}>
                <Form.Item
                    labelCol={{span: 14}}
                    label={<span className="grey">Regular Hours</span>}
                    className="ant-form-item-without-validation">
                    {formatDurationFromHours(RegularHours)}
                </Form.Item>
                {itemToEdit.Task.IsLabourLineSynced && (
                    <Form.Item
                        labelCol={{span: 14}}
                        label={<span
                            className="grey">Weekly OT Hours</span>}
                        className="ant-form-item-without-validation">
                        {formatDurationFromHours(WeeklyRegularOvertimeHours)}
                    </Form.Item>
                )}
                <Form.Item label={<span className="grey">OT 1 Hours</span>}
                           className="ant-form-item-without-validation"
                           labelCol={{span: 14}}>
                    {formatDurationFromHours(Overtime1Hours)}
                </Form.Item>
                <Form.Item label={<span className="grey">OT 2 Hours</span>}
                           className="ant-form-item-without-validation"
                           labelCol={{span: 14}}>
                    {formatDurationFromHours(Overtime2Hours)}
                </Form.Item>
                <Form.Item label={<span className="grey">Total Hours</span>}
                           className="ant-form-item-without-validation"
                           labelCol={{span: 14}}>
                    {formatDurationFromHours(TotalHours)}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label={<span className="grey">Regular Cost</span>}
                           className="ant-form-item-without-validation"
                           labelCol={{span: 13}}>
                    {formatAmount(RegularTotalCost || 0)}
                </Form.Item>
                {itemToEdit.Task.IsLabourLineSynced && (<Form.Item
                    label={<span
                        className="grey">Weekly OT Cost</span>}
                    className="ant-form-item-without-validation"
                    labelCol={{span: 13}}>
                    {formatAmount(WeeklyRegularOvertimeCost || 0)}
                </Form.Item>)}
                <Form.Item label={<span className="grey">OT 1 Cost</span>}
                           className="ant-form-item-without-validation"
                           labelCol={{span: 13}}>
                    {formatAmount(Overtime1Cost || 0)}
                </Form.Item>
                <Form.Item label={<span className="grey">OT 2 Cost</span>}
                           className="ant-form-item-without-validation"
                           labelCol={{span: 13}}>
                    {formatAmount(Overtime2Cost || 0)}
                </Form.Item>
                <Form.Item label={<span className="grey">Total Cost</span>}
                           className="ant-form-item-without-validation"
                           labelCol={{span: 13}}>
                    {formatAmount(TotalCost || 0)}
                </Form.Item>
            </Col>
        </Row>
        <Row style={{marginBottom: '16px'}}>
            <Col span={24}>
                {itemToEdit.Task.IsLabourLineSynced && (
                    <div className="grey calculated-label">(calculated from crew bookings)</div>)}
            </Col>
        </Row>
    </div>);
};

export default FinancialsBlock;