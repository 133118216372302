import React from "react";
import {Table} from "antd";
import {formatAmount} from "../../../helpers/money";
import moment from "moment";
import {formatMomentDuration} from "../../../helpers/time";

export const getLabourSummary = (pageData = []) => {
    let totalQty = 0;
    let totalCost = 0;
    let totalRegularCost = 0;
    let totalOvertimeCost = 0;
    let totalHoursDuration = moment.duration(0, "minutes");
    let totalDuration = moment.duration(0, "minutes");

    pageData.forEach((record) => {
        totalCost += record.TotalCost;
        totalRegularCost += record.RegularTotalCost;
        totalOvertimeCost += record.OvertimeTotalCost;
        totalDuration = moment.duration(record.Task.Duration).add(totalDuration.asMinutes(), "minutes");
        totalQty += record.Quantity;
        totalHoursDuration = totalHoursDuration.add(moment.duration(record.TotalHours, "hours"));
    });

    return {
        totalCost,
        totalRegularCost,
        totalOvertimeCost,
        totalQty,
        totalDuration: formatMomentDuration(totalDuration),
        totalHours: formatMomentDuration(totalHoursDuration),
    };
};

export const LabourTableFooter = (pageData) => {
    const {
        totalDuration,
        totalCost,
        totalRegularCost,
        totalOvertimeCost,
        totalQty,
        totalHours
    } = getLabourSummary(pageData);
    return (
        <>
            <Table.Summary.Row className="req-summary">
                <Table.Summary.Cell index={0}> </Table.Summary.Cell>
                <Table.Summary.Cell index={1}> </Table.Summary.Cell>
                <Table.Summary.Cell index={2}> </Table.Summary.Cell>
                <Table.Summary.Cell index={3}> </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                    <b>{totalDuration}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                    <b>{totalQty}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                    <b>{totalHours}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                    <div className="no-wrap"><b>{formatAmount(totalRegularCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                    <div className="no-wrap"><b>{formatAmount(totalOvertimeCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10}>
                    <div className="no-wrap"><b>{formatAmount(totalCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11}> </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    );
};

export const GroupLabourTableFooter = (pageData) => {
    const {
        totalDuration,
        totalCost,
        totalRegularCost,
        totalOvertimeCost,
        totalQty,
        totalHours
    } = getLabourSummary(pageData);
    return (
        <>
            <Table.Summary.Row className="req-summary">
                <Table.Summary.Cell index={0}> </Table.Summary.Cell>
                <Table.Summary.Cell index={1}> </Table.Summary.Cell>
                <Table.Summary.Cell index={2}> </Table.Summary.Cell>
                <Table.Summary.Cell index={3}> </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                    <b>{totalDuration}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}> </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                    <b>{totalQty}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                    <b>{totalHours}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                    <div className="no-wrap"><b>{formatAmount(totalRegularCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                    <div className="no-wrap"><b>{formatAmount(totalOvertimeCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10}>
                    <div className="no-wrap"><b>{formatAmount(totalCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11}> </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    );
};