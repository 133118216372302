import React from "react";
import {Form} from "antd";
import {formatAmount} from "../../../../helpers/money";

const CostDetailsBlock = ({itemToEdit, rulesets, workWeekSettings}) => {
    const ruleset = rulesets.find((r) => r.Id === itemToEdit.Ruleset?.Id);

    return (<>
        <Form.Item label={"Regular Cost"} className="ant-form-item-without-validation">
            {formatAmount(itemToEdit.Cost || 0)}/{itemToEdit.Unit}
        </Form.Item>
        <Form.Item label={"OT 1 Cost"} className="ant-form-item-without-validation">
            {formatAmount(itemToEdit.Cost * ruleset.OvertimeMultiplier1 || 0)}/{itemToEdit.Unit} after {ruleset.OvertimeStartAfter1} hours
            in an event
        </Form.Item>
        {ruleset.OvertimeMultiplier2 !== undefined && ruleset.OvertimeMultiplier2 !== null && ruleset.OvertimeMultiplier2 !== 0 && workWeekSettings.OvertimeRate !== undefined && workWeekSettings.OvertimeRate !== null && workWeekSettings.OvertimeRate !== 0 && ruleset.OvertimeStartAfter2 !== undefined && ruleset.OvertimeStartAfter2 !== null && ruleset.OvertimeStartAfter2 !== 0 && (
            <Form.Item label={"OT 2 Cost"} className="ant-form-item-without-validation">
                {formatAmount(itemToEdit.Cost * ruleset.OvertimeMultiplier2 || 0)}/{itemToEdit.Unit} after {ruleset.OvertimeStartAfter2} hours
                in an event
            </Form.Item>)}
        {workWeekSettings.StandardHoursThreshold !== undefined && workWeekSettings.StandardHoursThreshold !== null && workWeekSettings.StandardHoursThreshold !== 0 && (
            <Form.Item label={"Weekly OT Cost"} className="ant-form-item-without-validation">
                {formatAmount(itemToEdit.Cost * workWeekSettings.OvertimeRate || 0)}/{itemToEdit.Unit} after {workWeekSettings.StandardHoursThreshold} regular
                hours in a work week
            </Form.Item>)}
    </>);
};

export default CostDetailsBlock;