import api from "../api";
import store from "../redux/store";
import {setWorkWeekSettings} from "../redux/reducers/workWeekSettings";

const {dispatch} = store;

export const loadWorkWeekSettings = () => {
    const activePortfolioId = store.getState().projects.activePortfolio?.Id;

    return api.get(`api/WorkWeekSettings/GetByAccount?accountId=${activePortfolioId}`)
        .then((response) => {
            dispatch(setWorkWeekSettings(response));
            return response;
        });
};

export const updateWorkWeekSettings = (payload) =>
    api.post(`api/WorkWeekSettings/Update`, payload, `Update_WorkWeekSettings_${payload?.Id}`);