import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Card, Row, Col, Button} from "antd";
import WorkWeekSettingsModal from "./WorkWeekSettingsModal";
import {dayOptions} from "../../../../constants";
import {loadWorkWeekSettings} from "../../../../services/workWeekSettings";

const WorkWeekSettings = ({activePortfolio}) => {
    const [isAddModalVisible, setAddModalVisible] = useState(false);
    const [isReplaceModalVisible, setReplaceModalVisible] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [isSaved, setIsSaved] = useState(false);
    const workWeekSettings = useSelector((state) => state.workWeekSettings.settings);

    useEffect(() => {
        if (!activePortfolio) {
            return;
        }

        loadWorkWeekSettings();
    }, [activePortfolio, isSaved]);

    useEffect(() => {
        if (workWeekSettings) {
            setItemToEdit(workWeekSettings);
        }
    }, [workWeekSettings]);

    const onEdit = (item) => {
        setItemToEdit(item);
        setAddModalVisible(true);
    };

    const onCloseModal = () => {
        setAddModalVisible(false);
        setReplaceModalVisible(false);
    };

    return (<>
        <Card
            type="inner"
            title={<Row justify="space-between" align="middle">
                <Col>Work Week Settings</Col>
                <Col>
                    <Button type="primary" size="small" onClick={() => onEdit(workWeekSettings)}>Edit</Button>
                </Col>
            </Row>}
        >
            <Row className="row-margin">
                <Col span={24}>
                    Start work week
                    on {workWeekSettings?.StartDayOfWeek !== undefined ? dayOptions.find(day => day.value === workWeekSettings.StartDayOfWeek)?.label : "N/A"}.
                </Col>
            </Row>

            {workWeekSettings?.StandardHoursThreshold && workWeekSettings?.OvertimeRate ? (<>
                <Row className="row-margin">
                    <Col span={24}>
                        Overtime starts after {workWeekSettings?.StandardHoursThreshold || "N/A"} regular hours
                        in a work week.
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        Weekly OT Multiplier: {workWeekSettings?.OvertimeRate || "N/A"} times regular rate.
                    </Col>
                </Row>
            </>) : (<Row>
                <Col span={24}>No Weekly Overtime.</Col>
            </Row>)}
        </Card>
        <WorkWeekSettingsModal
            itemToEdit={itemToEdit}
            visible={isAddModalVisible}
            activePortfolioId={activePortfolio?.Id}
            onClose={onCloseModal}
            onSave={() => setIsSaved(!isSaved)}
        />
    </>);
};

export default WorkWeekSettings;