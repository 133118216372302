import React from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import GroupedTables from "../../Common/Tables/GroupedTable";
import {RequirementGroupBy} from "../FilterBar/GroupBy/GroupBy";
import {getRequirementsSummary} from "./RequirementsTableFooter";
import {formatAmount} from "../../../helpers/money";
import {Space} from "antd";
import {formatLocationName} from "../../../helpers/text";
import {ApiContactType} from "../../../constants/constants";
import {ProjectTag} from "../../Common/Projects/ProjectsTag";

function getAllProjects(data) {
    return _.chain(data)
        .map((d) => d.Project)
        .uniqBy((d) => d.Id)
        .value();
}

function getAllResources(data) {
    return _.chain(data)
        .map((d) => d.Resource)
        .uniqBy((d) => d?.Id)
        .value();
}

function getAllSources(data, allContacts) {
    const byTypeSorter = (source) => {
        // unknown source
        if (!source?.Id) return 100;

        const contact = allContacts.find((c) => c.Id === source?.ContactId);

        switch (contact?.Type) {
            case ApiContactType.Organization:
                return 1;
            case ApiContactType.Place:
            case ApiContactType.Space:
                return 2;
            case ApiContactType.Person:
                return 3;
            default:
                return 4;
        }
    };

    const byNameSorter = (source) => {
        const contact = allContacts.find((c) => c.Id === source?.ContactId);

        switch (contact?.Type) {
            case ApiContactType.Organization:
                return contact?.Company;
            case ApiContactType.Space:
            case ApiContactType.Place:
                return formatLocationName(contact);
            case ApiContactType.Person:
                return `${contact?.LastName || contact?.FirstName || contact?.Nickname || ""}`;
            default:
                return source?.Name;
        }
    };

    return _.chain(data)
        .flatMap((d) => d.Sources)
        .uniqBy((d) => d?.Default?.Id)
        .sortBy([byTypeSorter, byNameSorter])
        .value();
}

function getAllDepartments(data) {
    return _.chain(data)
        .filter((d) => d.Requirement.DepartmentTag)
        .map((d) => d.Requirement.DepartmentTag)
        .uniqBy((d) => d.AccountTagId)
        .sortBy((x) => x.Name)
        .value();
}

function getAllLocations(data, allContacts) {
    const mapper = (d) => ({
        Contact: allContacts.find((c) => c.Id === d.Location?.Id),
        Item: d,
    });

    const grouper = (d) => {
        // change request to group by space id, not location id
        return d?.Contact?.Id;
    };

    const grouped = _.chain(data).map(mapper).groupBy(grouper).value();

    const groups = _.keys(grouped)
        .map((id) => ({
            id,
            items: _.sortBy(
                grouped[id].map((m) => m.Item),
                [(m) => m.Location?.Name, (m) => m.Requirement.Name]
            ),
            contact: grouped[id][0].Contact,
        }))
        .filter((g) => g.contact);

    const groupsSorted = _.sortBy(groups, [(g) => formatLocationName(g.contact.Parent), (g) => formatLocationName(g.contact)]);

    return groupsSorted;
}

function getAllRequirements(data) {
    return _.chain(data)
        .map((d) => d.Requirement)
        .uniqBy((d) => d.Name)
        .value();
}

function getGroups(groupBy, dataSource, allContacts, allProjects) {
    let groups = [];
    let noGroupItems = [];
    let noGroupTitle = "";
    let noGroupText = null;

    const dataSourceSorted = _.sortBy(dataSource, (d) => d.Requirement.Name);

    const TextControl = ({items}) => {
        const {totalQty, totalCost} = getRequirementsSummary(items);
        return (
            <>
                <Space direction="horizontal" size="large">
                    <span style={{width: "60px"}}>{totalQty}</span>
                    <span style={{
                        width: "100px",
                        marginRight: "140px",
                        marginLeft: "20px",
                        display: "inline-block"
                    }}>{formatAmount(totalCost)}</span>
                </Space>
            </>
        );
    };

    const mergeSourceGroups = (unmerged) => {
        const merged = _.chain(unmerged)
            .flatMap((g) => g.items)
            .map((m) => m.Sources[0].ContactId)
            .uniq()
            .map((id) => ({contactId: id, group: null}))
            .value();

        _.forEach(unmerged, (g) => {
            const contactId = g.items.map((m) => m.Sources[0].ContactId)[0];
            let item = merged.find((m) => m.contactId === contactId) || {group: null};

            if (!item.group) {
                item.group = g;
            } else {
                const items = _.sortBy([...item.group.items, ...g.items], (m) => m.Requirement.Name);
                item.group = {
                    ...item.group,
                    items: items,
                    rightText: <TextControl items={items}/>,
                };
            }
        });

        return merged.map((g) => g.group).filter((g) => g);
    };

    switch (groupBy) {
        case RequirementGroupBy.Project:
            const projects = getAllProjects(dataSourceSorted);
            const unsortedProjects = projects.map((group) => {
                const projectItems = dataSourceSorted.filter((item) => item.Project.Id === group.Id);
                const project = allProjects.find((p) => p.Id === group.Id);
                return {
                    name: group.Name,
                    level: 250,
                    group: <ProjectTag project={project}/>,
                    items: projectItems,
                    rightText: <TextControl items={projectItems}/>,
                    rightTextClass: "header-totals-text",
                };
            });

            groups = _.sortBy(unsortedProjects, (g) => g.name);

            break;
        case RequirementGroupBy.Resource:
            const resources = getAllResources(dataSourceSorted);

            const unsortedResources = resources
                .filter((group) => group)
                .map((group) => {
                    const resourceItems = dataSourceSorted.filter((item) => item.Resource?.Id === group.Id);

                    return {
                        group: group.Name,
                        level: 250,
                        items: resourceItems,
                        rightText: <TextControl items={resourceItems}/>,
                        rightTextClass: "header-totals-text",
                    };
                });

            groups = _.sortBy(unsortedResources, (g) => g.group);

            noGroupItems = dataSourceSorted.filter((item) => !item.Resource);
            noGroupTitle = "No Resources";
            noGroupText = <TextControl items={noGroupItems}/>;

            break;
        case RequirementGroupBy.Source:
            const sources = getAllSources(dataSourceSorted, allContacts);

            const unmergedGroups = sources
                .filter((s) => s)
                .map((source) => {
                    const sourceItems = dataSourceSorted
                        .filter((item) => item.Sources.find((s) => s.Id === source.Id))
                        .map((item) => ({...item, Sources: [item.Sources.find((s) => s.Id === source.Id)]}))
                        .filter((m) => m.Sources[0].Value?.Quantity > 0);

                    return {
                        group: source.Name,
                        level: 250,
                        items: sourceItems,
                        rightText: <TextControl items={sourceItems}/>,
                        rightTextClass: "header-totals-text",
                    };
                });

            groups = mergeSourceGroups(unmergedGroups);

            noGroupItems = dataSourceSorted.filter((item) => !(item.Sources?.length > 0));
            noGroupTitle = "No Sources";
            noGroupText = <TextControl items={noGroupItems}/>;

            break;

        case RequirementGroupBy.Location:
            const locations = getAllLocations(dataSourceSorted, allContacts);

            groups = locations.map((group) => {
                const {contact, items} = group;
                const name = formatLocationName(contact);

                return {
                    group: name,
                    level: 250,
                    items: items,
                    rightText: <TextControl items={items}/>,
                    rightTextClass: "header-totals-text",
                };
            });

            noGroupItems = _.sortBy(
                dataSourceSorted.filter((item) => !item.Location),
                (m) => m.Requirement.Name
            );
            noGroupTitle = "No Locations";
            noGroupText = <TextControl items={noGroupItems}/>;

            break;
        case RequirementGroupBy.Department:
            const departments = getAllDepartments(dataSourceSorted);

            groups = departments.map((group) => {
                const departmentItems = dataSourceSorted.filter(
                    (item) => item.Requirement.DepartmentTag?.AccountTagId === group.AccountTagId
                );
                return {
                    group: group.Name,
                    level: 250,
                    items: departmentItems,
                    rightText: <TextControl items={departmentItems}/>,
                    rightTextClass: "header-totals-text",
                };
            });

            noGroupItems = dataSourceSorted.filter((item) => !item.Requirement.DepartmentTag);
            noGroupTitle = "No Department";
            noGroupText = <TextControl items={noGroupItems}/>;

            break;
        case RequirementGroupBy.Requirement:
            const requirements = getAllRequirements(dataSourceSorted);

            const unsortedReqs = requirements.map((group) => {
                const requirementItems = dataSourceSorted.filter((item) => item.Requirement.Name === group.Name);
                return {
                    group: group.Name,
                    level: 250,
                    items: requirementItems,
                    rightText: <TextControl items={requirementItems}/>,
                    rightTextClass: "header-totals-text",
                };
            });

            groups = _.sortBy(unsortedReqs, (g) => g.group);

            break;
        default:
            throw new Error(`${groupBy} is not implemented`);
    }

    if (noGroupItems.length !== 0) {
        groups.push({
            group: noGroupTitle,
            level: 250,
            items: noGroupItems,
            rightText: noGroupText,
            rightTextClass: "header-totals-text",
        });
    }

    groups.forEach((group) => {
        group.keys = group.items.map((i) => i.Id);
    });

    return groups;
}

const GroupRequirementsTable = ({columns, dataSource, onRow, groupByFilter, loading, footer}) => {
    const allContacts = useSelector((state) => state.contacts.contacts);
    const allProjects = useSelector((state) => state.projects.projects);

    return (
        <GroupedTables
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            onRow={onRow}
            footer={footer}
            getGroups={() => getGroups(groupByFilter.value, dataSource, allContacts, allProjects)}
        />
    );
};

export {getGroups};

export default GroupRequirementsTable;
