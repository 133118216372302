import React from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import GroupedTables from "../../Common/Tables/GroupedTable";
import {RequirementGroupBy} from "./../FilterBar/GroupBy/GroupBy";
import {getLabourSummary} from "./LabourTableFooter";
import {formatAmount} from "../../../helpers/money";
import {Space} from "antd";
import {ProjectTag} from "../../Common/Projects/ProjectsTag";

function getAllProjects(data) {
    return _.chain(data)
        .map((d) => d.Task.Project)
        .uniqBy((d) => d.Id)
        .value();
}

function getAllEvents(data) {
    return _.chain(data)
        .map((d) => d.Task)
        .uniqBy((d) => d.Id)
        .value();
}

function getAllPosition(data) {
    return _.chain(data)
        .filter((d) => d.Position)
        .map((d) => d.Position)
        .uniqBy((d) => d.Id)
        .value();
}

function getAllSources(data) {
    return _.chain(data)
        .filter((d) => d.Position?.Source)
        .map((d) => d.Position.Source)
        .uniqBy((d) => d.Id)
        .value();
}

function getAllDepartments(data) {
    return _.chain(data)
        .filter((d) => d.Position?.DepartmentTag)
        .map((d) => d.Position.DepartmentTag)
        .uniqBy((d) => d.AccountTagId)
        .sortBy((x) => x.Name)
        .value();
}

function getGroups(groupBy, dataSource, allContacts, allProjects) {
    let groups = [];
    let noGroupItems = [];
    let noGroupTitle = "";
    let noGroupText = null;

    const TextControl = ({items}) => {
        const {
            totalQty,
            totalCost,
            totalRegularCost,
            totalOvertimeCost,
            totalDuration,
            totalHours
        } = getLabourSummary(items);
        return (
            <>
                <Space direction="horizontal" size={0} className="header-totals-text-only">
                    <span style={{width: "205px"}}>{totalDuration}</span>
                    <span style={{width: "45px"}}>{totalQty}</span>
                    <span style={{width: "75px"}}>{totalHours}</span>
                    <span style={{width: "95px"}}>{formatAmount(totalRegularCost)}</span>
                    <span style={{width: "95px"}}>{formatAmount(totalOvertimeCost)}</span>
                    <span style={{width: "95px", marginRight: "255px"}}>{formatAmount(totalCost)}</span>
                </Space>
            </>
        );
    };

    switch (groupBy) {
        case RequirementGroupBy.Project:
            const projects = getAllProjects(dataSource);

            const unsortedProjects = projects.map((group) => {
                const projectItems = dataSource.filter((item) => item.Task.Project.Id === group.Id);
                const project = allProjects.find((p) => p.Id === group.Id);

                return {
                    name: group.Name,
                    level: 250,
                    group: <ProjectTag project={project}/>,
                    items: projectItems,
                    rightText: <TextControl items={projectItems}/>,
                };
            });

            groups = _.sortBy(unsortedProjects, (g) => g.name);

            break;
        case RequirementGroupBy.Event:
            const events = getAllEvents(dataSource);
            groups = events.map((group) => {
                let items = dataSource.filter((item) => item.Task.Id === group.Id);

                return {
                    group: group.Name,
                    items: items,
                    level: 250,
                    rightText: <TextControl items={items}/>,
                };
            });

            break;
        case RequirementGroupBy.Position:
            const positions = getAllPosition(dataSource);
            groups = positions.map((group) => {
                let items = dataSource.filter((item) => item.Position?.Id === group.Id);

                return {
                    group: group.Name,
                    items: items,
                    level: 250,
                    rightText: <TextControl items={items}/>,
                };
            });

            noGroupItems = dataSource.filter((item) => !item.Position);
            noGroupTitle = "No Position";
            noGroupText = <TextControl items={noGroupItems}/>;

            break;
        case RequirementGroupBy.Source:
            const sources = getAllSources(dataSource, allContacts);

            groups = sources.map((group) => {
                let items = dataSource.filter((item) => item.Position?.Source?.Id === group.Id);

                return {
                    group: group.Name,
                    items: items,
                    level: 250,
                    rightText: <TextControl items={items}/>,
                };
            });

            noGroupItems = dataSource.filter((item) => !item.Position?.Source);
            noGroupTitle = "No Position Source";
            noGroupText = <TextControl items={noGroupItems}/>;

            break;
        case RequirementGroupBy.Department:
            const departments = getAllDepartments(dataSource);

            groups = departments.map((group) => {
                const departmentItems = dataSource.filter((item) => item.Position?.DepartmentTag?.AccountTagId === group.AccountTagId);
                return {
                    group: group.Name,
                    level: 250,
                    items: departmentItems,
                    rightText: <TextControl items={departmentItems}/>,
                };
            });

            noGroupItems = dataSource.filter((item) => !item.Position?.DepartmentTag);
            noGroupTitle = "No Department";
            noGroupText = <TextControl items={noGroupItems}/>;

            break;
        default:
            throw new Error(`${groupBy} is not implemented`);
    }

    if (noGroupItems.length !== 0) {
        groups.push({
            group: noGroupTitle,
            level: 250,
            items: noGroupItems,
            rightText: noGroupText,
        });
    }

    groups.forEach((group) => {
        group.keys = group.items.map((i) => i.Id);
    });

    return groups;
}

const GroupLabourTable = ({columns, dataSource, onRow, groupByFilter, loading, footer}) => {
    const allContacts = useSelector((state) => state.contacts.contacts);
    const allProjects = useSelector((state) => state.projects.projects);

    return (
        <GroupedTables
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            onRow={onRow}
            footer={footer}
            getGroups={() => getGroups(groupByFilter.value, dataSource, allContacts, allProjects)}
        />
    );
};

export {getGroups};

export default GroupLabourTable;