import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Spin} from "antd";
import Drift from "react-driftjs";
import {BrowserRouter as Router, Switch} from "react-router-dom";

import {DriftAppId} from "../../constants";
import {whoAmI} from "../../services/auth";
import {loadPortfolios} from "../../services/projects";

import "./App.css";
import "../../style/themeOverride.less";
import {getPublicRoutes, getRoutes, getRoutesWithChildren} from "../../helpers";

function App() {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const isPortfolioLoading = useSelector((state) => state.projects.isPortfolioLoading);
    const user = useSelector((state) => state.currentUser.user);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await whoAmI();
            } catch (err) {
            } finally {
                setLoading(false);
            }
        };

        fetchData().catch(err => console.error(err));
    }, [dispatch]);

    useEffect(() => {
        if (user) {
            loadPortfolios().catch(err => console.error(err));
        }
    }, [user]);

    return (
        <div className="App">
            {!!DriftAppId && <Drift appId={DriftAppId}/>}
            <Spin spinning={isLoading || isPortfolioLoading}>
                <Router>
                    <Switch>
                        {getPublicRoutes().map((r) => getRoutesWithChildren(r))}
                        {getRoutes().map((r) => getRoutesWithChildren(r, activePortfolio))}
                    </Switch>
                </Router>
            </Spin>
        </div>
    );
}

export default App;
