import {Table} from "antd";
import moment from "moment";
import React from "react";
import {formatDurationFromHours, formatMomentDuration} from "../../helpers/time";
import {formatAmount} from "../../helpers/money";

export const getTotalDuration = (pageData) => {
    return (pageData.reduce((prev, curr) => prev.add(moment.duration(curr.Duration)), moment.duration(0, "minutes")) || moment.duration(0, "minutes"));
};

export const getTotalHours = (pageData) => {
    return pageData.reduce((total, curr) => total + (curr.TotalHours || 0), 0);
};

export const getTotalRegularCost = (pageData) => {
    return pageData.reduce((total, curr) => total + (curr.RegularCost || 0), 0);
};

export const getTotalOvertimeCost = (pageData) => {
    return pageData.reduce((total, curr) => total + (curr.OvertimeTotalCost || 0), 0);
};

export const getTotalCost = (pageData) => {
    return pageData.reduce((total, curr) => total + (curr.TotalCost || 0), 0);
};

export const BookingsTableFooter = (pageData) => {
    const duration = getTotalDuration(pageData);
    const totalRegularCost = getTotalRegularCost(pageData);
    const totalOvertimeCost = getTotalOvertimeCost(pageData);
    const totalCost = getTotalCost(pageData);

    return (<>
        <Table.Summary.Row className="req-summary">
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell>{formatMomentDuration(duration)} </Table.Summary.Cell>
            <Table.Summary.Cell>{formatAmount(totalRegularCost)} </Table.Summary.Cell>
            <Table.Summary.Cell>{formatAmount(totalOvertimeCost)} </Table.Summary.Cell>
            <Table.Summary.Cell>{formatAmount(totalCost)} </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
        </Table.Summary.Row>
    </>);
};

export const GroupBookingsTableFooter = (pageData) => {
    const totalHours = getTotalHours(pageData);
    const totalRegularCost = getTotalRegularCost(pageData);
    const totalOvertimeCost = getTotalOvertimeCost(pageData);
    const totalCost = getTotalCost(pageData);

    return (<>
        <Table.Summary.Row className="req-summary">
            <Table.Summary.Cell></Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
            <Table.Summary.Cell>{formatDurationFromHours(totalHours)} </Table.Summary.Cell>
            <Table.Summary.Cell>{formatAmount(totalRegularCost)} </Table.Summary.Cell>
            <Table.Summary.Cell>{formatAmount(totalOvertimeCost)} </Table.Summary.Cell>
            <Table.Summary.Cell>{formatAmount(totalCost)} </Table.Summary.Cell>
            <Table.Summary.Cell> </Table.Summary.Cell>
        </Table.Summary.Row>
    </>);
};
