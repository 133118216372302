import React, {useState, useEffect, useRef} from "react";
import {Modal, Form, Space, InputNumber, message} from "antd";
import {ExclamationCircleOutlined, ToolOutlined} from "@ant-design/icons";
import {SelectWithFormItem} from "../../../Common/Input";
import {formatToFixedFlex} from "../../../../helpers/text";
import {dayOptions} from "../../../../constants";
import {updateWorkWeekSettings} from "../../../../services/workWeekSettings";
import {errorFromHttpResponse} from "../../../../helpers/error";

const WorkWeekSettingsModal = ({visible, onClose, itemToEdit, activePortfolioId, onSave}) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const form = useRef();

    useEffect(() => {
        if (!activePortfolioId || !form?.current) return;


        if (itemToEdit) {
            form.current.setFieldsValue(itemToEdit);
        } else {
            form.current.resetFields();
        }

    }, [activePortfolioId, itemToEdit, visible]);

    const onOk = async () => {
        try {
            await form.current.validateFields();
            setConfirmLoading(true);

            Modal.confirm({
                title: "Are you sure you want to continue?",
                icon: <ExclamationCircleOutlined/>,
                content: "Editing Work Week settings may affect the costs for all crew bookings including those in archived projects.",
                okText: "Yes, Save",
                cancelText: "Cancel",
                okButtonProps: {danger: true},
                async onOk() {
                    try {
                        const values = form.current.getFieldsValue();
                        await updateWorkWeekSettings({...values, AccountId: activePortfolioId});
                        onSave();
                        onCancel();
                    } catch (error) {
                        const errorText = errorFromHttpResponse(error);
                        message.error(errorText);
                    } finally {
                        setConfirmLoading(false);
                    }
                },
                onCancel() {
                    setConfirmLoading(false);
                },
            });
        } catch (error) {
            setConfirmLoading(false);
        }
    };

    const onCancel = () => {
        form.current.resetFields();
        onClose();
    };

    const handleFieldChange = () => {
        form.current.validateFields(["StandardHoursThreshold", "OvertimeRate"]);
    };

    return (
        <Modal
            className="add-ruleset-modal"
            title={<><ToolOutlined/> &nbsp; Edit Work Week Settings</>}
            visible={visible}
            maskClosable={false}
            confirmLoading={confirmLoading}
            onOk={onOk}
            onCancel={onCancel}
            okText="Save"
            cancelText="Cancel"
            width={"560px"}
        >
            <Form
                ref={form}
                name="editWorkWeekSettings"
                labelCol={{span: 10}}
                wrapperCol={{span: 15}}
                layout="horizontal"
                size="middle"
                requiredMark={false}
                initialValues={{StartWeekOn: 1}}
            >
                <SelectWithFormItem
                    label="Start calendar week on"
                    name="StartDayOfWeek"
                    style={{width: "300px"}}
                    options={dayOptions}
                    onChanged={handleFieldChange}
                />
                <Form.Item label="Overtime starts after:" className="form-item">
                    <Space>
                        <Form.Item
                            name="StandardHoursThreshold"
                            noStyle
                            rules={[{
                                validator(_, value) {
                                    const weeklyMultiplier = form.current.getFieldValue("OvertimeRate");
                                    if (!value && weeklyMultiplier) {
                                        return Promise.reject(new Error("Overtime starts after"));
                                    }
                                    return Promise.resolve();
                                },
                            },]}
                        >
                            <InputNumber
                                className="input-number"
                                min={1}
                                step={1}
                                controls={false}
                                onChange={(value) => {
                                    if (value === null || value === undefined) {
                                        form.current.setFieldsValue({StandardHoursThreshold: undefined});
                                    } else if (!Number.isInteger(value)) {
                                        form.current.setFieldsValue({StandardHoursThreshold: Math.floor(value)});
                                    }
                                    handleFieldChange();
                                }}
                            />
                        </Form.Item>
                        <span> regular hours in a work week.</span>
                    </Space>
                </Form.Item>
                <Form.Item label="Weekly OT multiplier" className="form-item">
                    <Space>
                        <Form.Item
                            name="OvertimeRate"
                            noStyle
                            rules={[{
                                validator(_, value) {
                                    const standardHoursThreshold = form.current.getFieldValue("StandardHoursThreshold");
                                    if (!value && standardHoursThreshold) {
                                        return Promise.reject(new Error("Weekly OT multiplier"));
                                    }
                                    return Promise.resolve();
                                },
                            },]}
                        >
                            <InputNumber
                                className="input-number"
                                parser={(v) => parseFloat(v) && parseFloat(v).toFixed(2)}
                                formatter={formatToFixedFlex}
                                min={0}
                                controls={false}
                                onChange={handleFieldChange}
                            />
                        </Form.Item>
                        <span> times regular rate.</span>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default WorkWeekSettingsModal;