import React, {useState, useEffect, useCallback} from "react";
import {Collapse, Form, message} from "antd";
import {DollarCircleOutlined, InfoCircleOutlined, ToolFilled, ToolOutlined} from "@ant-design/icons";
import {NewRecordState} from "../../../../constants/constants";
import {continueEdit} from "../../../../redux/reducers/detailsPanel";
import {errorFromHttpResponse, isRequestCanceled} from "../../../../helpers/error";
import {useSelector, useDispatch} from "react-redux";
import InformationBlock from "./InformationBlock";
import PositionBlock from "./PositionBlock";
import FinancialsBlock from "./FinancialsBlock";
import "./LabourLineDetailsPanel.scss";
import {createLabourLine, loadLabourLines, updateLabourLine} from "../../../../services/labour";
import CrewBookingsBlock from "./CrewBookingsBlock";

const InformationHeader = (
    <>
        <InfoCircleOutlined/> Labour Line Information
    </>
);
const PositionHeader = (
    <>
        <ToolOutlined/> Position
    </>
);
const FinancialsHeader = (
    <>
        <DollarCircleOutlined/> Financials
    </>
);

const CrewBookingsHeader = (
    <>
        <ToolFilled/> Crew Bookings
    </>
);

const prepareFormData = (data) => {
    const formData = {
        ...data,
        ProjectId: data.Task.ProjectId.toString(),
        TaskId: data.Task.Id,
    };

    return formData;
};

const LabourLineDetailsPanel = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [editItemState, setEditItemState] = useState(NewRecordState.Empty);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const isOptionalFieldDisabled = editItemState !== NewRecordState.ReadyForEdit;

    const workWeekSettings = useSelector((state) => state.workWeekSettings.settings);
    const selectedProjects = useSelector((state) => state.projects.activeProjects);
    const singleProject = selectedProjects.length === 1 && !itemToEdit ? selectedProjects[0] : null;

    useEffect(() => {
        if (!itemToEdit) {
            return;
        }
        setEditItemState(NewRecordState.ReadyForEdit);

        const formData = prepareFormData(itemToEdit);
        form.setFieldsValue(formData);
    }, [itemToEdit, form]);

    const prepareAndCreate = useCallback(
        (finishedForm) => {
            createLabourLine(finishedForm.TaskId)
                .catch((err) => {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    throw new Error(errorText);
                })
                .then((response) => {
                    dispatch(continueEdit({item: response}));
                    setEditItemState(NewRecordState.ReadyForEdit);
                    loadLabourLines(activePortfolio.Id);
                    message.success(`Labour Line was created`);
                })
                .catch(console.error);

            setEditItemState(NewRecordState.RequiredFieldsSubmitted);
        },
        [itemToEdit?.Id, dispatch]
    );

    const prepareAndUpdate = useCallback(
        (finishedForm) => {
            const updatePayload = {
                Id: itemToEdit.Id,
                PositionId: finishedForm.Position?.Id,
                Cost: finishedForm.Cost,
                Unit: finishedForm.Unit,
                MealBreak: finishedForm.MealBreak,
                Quantity: finishedForm.Quantity,
                Notes: finishedForm.Notes,
            };

            updateLabourLine(updatePayload)
                .then((response) => {
                    dispatch(continueEdit({item: response}));
                    loadLabourLines(activePortfolio.Id);
                })
                .catch((err) => {
                    if (!isRequestCanceled(err)) {
                        const errorText = errorFromHttpResponse(err);
                        message.error(errorText);
                        throw new Error(errorText);
                    }
                })
                .catch(console.error);
        },
        [itemToEdit?.Id, dispatch]
    );

    const onFinish = useCallback(
        (finishedForm) => {
            if (editItemState === NewRecordState.Empty) {
                prepareAndCreate(finishedForm);
            }
            if (editItemState === NewRecordState.ReadyForEdit) {
                prepareAndUpdate(finishedForm);
            }
        },
        [editItemState, prepareAndCreate, prepareAndUpdate]
    );

    const save = useCallback(async () => {
        try {
            const values = isOptionalFieldDisabled ? await form.getFieldsValue() : await form.validateFields();

            if (!isOptionalFieldDisabled || (values.TaskId && values.ProjectId)) {
                setEditItemState(NewRecordState.ReadyForEdit);
                onFinish(values);
            }
        } catch (err) {
            console.error(err);
        }
    }, [form, onFinish]);

    return (
        <div className="side-panel with-collapse labour-details-panel">
            <Collapse defaultActiveKey={["Information", "Position", "Financials", "CrewBookings"]}>
                <Collapse.Panel key="Information" header={InformationHeader}>
                    <Form
                        form={form}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        layout="horizontal"
                        size="middle"
                        initialValues={{ProjectId: singleProject ? [singleProject?.Id.toString()] : []}}>
                        <InformationBlock form={form} save={save} itemToEdit={itemToEdit}
                                          singleProject={singleProject}/>
                    </Form>
                </Collapse.Panel>
                <Collapse.Panel key="Position" header={PositionHeader} className="req-management-panel">
                    <Form
                        className="req-panel-well"
                        form={form}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        layout="horizontal"
                        size="middle">
                        <PositionBlock form={form} save={save} itemToEdit={itemToEdit}
                                       isOptionalFieldDisabled={isOptionalFieldDisabled}/>
                    </Form>
                </Collapse.Panel>
                <Collapse.Panel key="Financials" header={FinancialsHeader} className="req-management-panel">
                    <Form
                        className="req-panel-well"
                        form={form}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        layout="horizontal"
                        size="middle">
                        {itemToEdit?.Position && (
                            <FinancialsBlock
                                form={form}
                                save={save}
                                itemToEdit={itemToEdit}
                                workWeekSettings={workWeekSettings}
                                isOptionalFieldDisabled={isOptionalFieldDisabled}
                            />
                        )}
                    </Form>
                </Collapse.Panel>
                {itemToEdit?.Task?.IsLabourLineSynced && (
                    <Collapse.Panel key="CrewBookings" className="req-management-panel" header={CrewBookingsHeader}>
                        <CrewBookingsBlock itemToEdit={itemToEdit}></CrewBookingsBlock>
                    </Collapse.Panel>
                )}
            </Collapse>
        </div>
    );
};

export default LabourLineDetailsPanel;
